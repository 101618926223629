import Footer from "../../footer/Footer";
import BottomPart from "./bottomPart/BottomPart";
import MiddlePart from "./middlePart/MiddlePart";
import TopPart from "./topPart/TopPart";

function Accueil() {
  return (
    <div style={{ width: '100%', overflowX: 'hidden' }}> {/* Ajoutez cette div pour éviter le défilement horizontal */}
      <div style={{ display: "flex", flexDirection: "column", width: '100%' }}>
        <TopPart />
        <MiddlePart />
        <BottomPart />
        <Footer />
      </div>
    </div>
  );
}

export default Accueil;
