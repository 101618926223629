import React from 'react';
import './TopPart.css'; // Importer le fichier CSS pour appliquer les styles
import backImage from '../../../../ressources/image/chambre.jpeg';


function TopPart() {
  return (
    <div className="top-part">
      <img src={backImage} alt="Description de l'image" className="top-image" />
      <div className="text-container">
        <h1 className="title">Conciergerie</h1>
      </div>
    </div>
  );
}

export default TopPart;
