import React from 'react';
import DoveIconsSVG from './DoveIcons.svg';

function DoveIcons() {
    return (
        <div>
            <img src={DoveIconsSVG} alt="Increase Icons" />
        </div>
    );
}

export default DoveIcons;