import React from 'react';
import MoneyIconsSVG from './MoneyIcons.svg';

function MoneyIcons() {
    return (
        <div>
            <img src={MoneyIconsSVG} alt="Money Icons" />
        </div>
    );
}

export default MoneyIcons;