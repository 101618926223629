import React from 'react';
import SatisIconsSVG from './SatisIcons.svg';

function SatisIcons() {
    return (
        <div>
            <img src={SatisIconsSVG} alt="Satisfaction Icons" />
        </div>
    );
}

export default SatisIcons;