import React from 'react';
import backImage from '../../../../ressources/image/home-design.jpg';
import './TopPart.css';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import TodayIcon from '@mui/icons-material/Today';

function TopPart() {
  return (
    <div className="accueil-container">
      <div
        className="background-image"
        style={{
          backgroundImage: `url(${backImage})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          height: 'calc(100vh - 160px)',
        }}
      >
        <div className="overlay">
          <div className="text-band">
            <h1 className='titre'>Votre Conciergerie sur le Val d'Oise</h1>
            <h3 className='sous-titre'>Simplifiez-vous la vie avec AMANA Conciergerie !</h3>
            <div className="button-container">
              <Link to="/contact" className="link-button">
                <Button variant="contained" className="rendez-vous-button">
                  <TodayIcon style={{ marginRight: '5px' }} /> 
                  Prendre Rendez-Vous
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TopPart;
