import * as React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import { Facebook, Instagram, Twitter } from "@mui/icons-material";
import { Avatar, Box, useMediaQuery } from "@mui/material";
import logo from '../../ressources/logo/LOGO-V2-4K.png';



function Footer() {
  const isMobile = useMediaQuery('(max-width:600px)'); // Vérifie si l'écran est de taille mobile

  return (
    <div>
    <Box
      component="footer"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[200]
            : theme.palette.grey[800],
        p: 6,
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={5}>
          <Grid item xs={12} sm={4}>
            <Avatar alt="Logo" src={logo} style={{ width: '200px', height: 'auto' }} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography style={{ fontFamily : 'PoliceTitre', fontWeight : 'bold' }} variant="h6" color="text.primary" gutterBottom>
              Contactez-nous
            </Typography>
            <Typography style={{ fontFamily : 'PoliceTitre', fontWeight : 'bold' }} variant="body2" color="text.secondary">
              95100 Argenteuil
            </Typography>
            <Typography style={{ fontFamily : 'PoliceTitre', fontWeight : 'bold' }} variant="body2" color="text.secondary">
              Email: amana.conciergerie@gmail.com
            </Typography>
            <Typography style={{ fontFamily : 'PoliceTitre', fontWeight : 'bold' }} variant="body2" color="text.secondary">
              Phone: +33 7 49 01 93 49
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography style={{ fontFamily : 'PoliceTitre', fontWeight : 'bold' }} variant="h6" color="text.primary" gutterBottom>
              Suivez-nous
            </Typography>
            <Link href="https://www.facebook.com/profile.php?id=61558744658116" color="inherit">
              <Facebook />
            </Link>
            <Link
              href="https://www.instagram.com/amana.conciergerie/"
              color="inherit"
              sx={{ pl: 1, pr: 1 }}
            >
              <Instagram />
            </Link>
          </Grid>
        </Grid>
        <Box mt={isMobile ? 2 : -7} className="copyright">
          <Typography variant="body2" color="text.secondary" align="center">
            {"Copyright © "}
            <Link className="amana-link" color="inherit" href="amana-cg.vercel.app">
              Amana Conciergerie
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
          </Typography>
        </Box>
      </Container>
    </Box>
    </div>
  );
}

export default Footer;
