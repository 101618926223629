import React from 'react';
import { styled } from '@mui/system';
import Footer from '../../footer/Footer';
import TopPart from './topPart/TopPart';

function Contact() {
  return (
    <div style={{ width: '100%', overflowX: 'hidden' }}>
      <div style={{display: "flex", flexDirection: "column", width:'fit-content'}}>
          <TopPart/> 
          <Footer />
      </div>
    </div>
  );
}

export default Contact;
