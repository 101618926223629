import Footer from "../../footer/Footer";
import MidllePart from "./midllePart/MiddlePart";
import TopPart from "./topPart/TopPart"

function OffreCg() {
  return (
    <div style={{ width: '100%', overflowX: 'hidden' }}>
      <div style={{display: "flex", flexDirection: "column", width:'fit-content'}}>
        <TopPart />
        <MidllePart/>
        <Footer />
      </div>
    </div>
  );
}

export default OffreCg;
