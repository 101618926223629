import React from "react";
import "./App.css";
import './color.css';
import { Component } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes 
} from 'react-router-dom'
import './App.css';
import Accueil from "./composants/body/accueil/Accueil";
import Header from "./composants/header/Header";
import OffreCg from "./composants/body/offreCg/offreCg";
import Contact from "./composants/body/contact/Contact";
import Footer from "./composants/footer/Footer";



class App extends Component {
  render() {
    return (
      <Router>
        <Header/>
            <Routes>
              <Route path="/" exact element={<Accueil/>} />
              <Route path="/offre-conciergerie" exact element={<OffreCg/>} />
              <Route path="/contact" exact element={<Contact/>} />
            </Routes>
      </Router >
    );
  }
}

export default App;