import React from 'react';
import './BottomPart.css';
import topLeftImage from '../../../../ressources/image/salon4.jpg'
import bottomRightImage from '../../../../ressources/image/salon2.jpg'

function BottomPart() {
        const topRightText="Texte en haut à droite" 
        const bottomLeftText="Texte en bas à gauche" 
  return (
    <div className="bottom-part-container">
      <div className="top-left-image-container">
        <img src={topLeftImage} alt="Top Left" className="image" />
      </div>
      <div className="bottom-right-image-container">
        <img src={bottomRightImage} alt="Bottom Right" className="image" />
      </div>
      <div className="top-right-text">
            <div className="welcome-container">
                <div className="titreBottom">Vous êtes propriétaire</div>
                <div className="line"></div>
            </div>
            <div className='texte'>
            <p className='texte-size'>Découvrez notre offre de conciergerie immobilière tout inclus.</p>
            <p className='texte-size'>Votre solution complète pour une gestion sans souci de votre bien immobilier sur le Val d'Oise.</p>
          </div>
       </div>
      <div className="bottom-left-text">
        <div className="welcome-container">
                <div className="titreBottom">Vous êtes voyageur</div>
                <div className="line"></div>
        </div>
        <div className='texte'>
            <p className='texte-size'>Découvrez notre sélection de biens en location courte durée sur le Val d'Oise.</p>
            <p className='texte-size'>Des hébergements d'exception pour des séjours inoubliables.</p>
          </div>
      </div>
    </div>
  );
}

export default BottomPart;
