import React, { useEffect } from 'react';
import image from '../../../../ressources/image/chambre2.jpeg';
import HomeIcon from '@mui/icons-material/Home';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import './TopPart.css'; // Importez le fichier CSS pour les styles
import { useMediaQuery } from '@mui/material';

const TopPart = () => {
  const isMobile = useMediaQuery('(max-width:600px)'); // Vérifie si l'écran est de taille mobile

  useEffect(() => {
    // Chargement du script Calendly
    const script = document.createElement('script');
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      // Nettoyage du script lors du démontage du composant
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ display: 'flex' }}>
        <div className='image-container' style={{ flex: 1 }}>
          <img src={image} alt="Image" style={{ maxWidth: '100%', height: 'auto' }} />
        </div>
        <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <div className="welcome-container">
            <div className="line"></div>
            <div className="titre">Contact</div>
            <div className="line"></div>
          </div>
          <ul className="card-list">
            <li style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '10px' }}><HomeIcon style={{ fontSize: isMobile ? '40px' : '24px' }} /> <span style={{ marginLeft: '5px' }}>AMANA</span></li>
            <li style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '10px' }}><LocationOnIcon style={{ fontSize: isMobile ? '40px' : '24px' }} /> <span style={{ marginLeft: '5px' }}>95100 Argenteuil</span></li>
            <li style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '10px' }}><CallIcon style={{ fontSize: isMobile ? '40px' : '24px' }} /> <span style={{ marginLeft: '5px' }}>+33 7 49 01 93 49</span></li>
            <li style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '10px' }}><EmailIcon style={{ fontSize: isMobile ? '40px' : '24px' }} /> <span style={{ marginLeft: '5px' }}>amana.conciergerie@gmail.com</span></li>
            <li style={{ textAlign: 'center' }}>Du <strong>Lundi</strong> au  <strong>Samedi</strong> de 9h à 19h</li>
          </ul>
        </div>
      </div>
      <div className="calendly-inline-widget" data-url="https://calendly.com/amana-conciergerie/30min" style={{ overflow:'hidden', minwidth: '320px', height: isMobile ? '900px' : '750px' }}></div>
    </div>
  );
};

export default TopPart;
