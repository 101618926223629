import './MiddlePart.css';
import MoneyIcons from '../../../Icons/MoneyIcons/MoneyIcons.jsx';
import IncreaseIcons from '../../../Icons/IncreaseIcons/IncreaseIcons.jsx';
import SatisIcons from '../../../Icons/SatisfactionIcons/SatisIcons.jsx';
import DoveIcons from '../../../Icons/DoveIcons/DoveIcons.jsx';

function MiddlePart() {
    return (
      <div>
        <div className="main-container">
          <div className="welcome-container">
            <div className="line"></div>
            <div className="titre">Bienvenue</div>
            <div className="line"></div>
          </div>
          <h2>
            Chez AMANA Conciergerie, votre bien-être est notre engagement principal !
          </h2>
          <div className='texte'>
            <p>Nous vous assistons dans l'intendance de votre bien en location courte durée.</p>
            <p>Que vous soyez loin de votre domicile ou que votre emploi du temps soit chargé...</p>
            <b>Nous avons la solution parfaite pour vous !</b>
            <p>Imaginez avoir un service sur mesure à votre disposition, 24h/24 et 7j/7.</p>
            <b>Nous nous engageons à simplifier votre quotidien, à le rendre plus agréable et sans tracas.</b>
            <p>Confiez-nous les clés de votre logement et nous en prendrons soin avec la plus grande attention, comme si c'était le nôtre, tout en améliorant votre rentabilité !</p>
            <p>Retrouvez la tranquillité et la sérénité d'esprit grâce à notre service de conciergerie haut de gamme, entièrement personnalisé selon vos besoins.</p>
          </div>
        </div>
        <div className="circle-container">
          <div className="circle">
              <MoneyIcons></MoneyIcons> 
              <div className='texteCercle'>
                REVENUS LOCATIFS
              </div>
              <div className='texteCercle'>
                MAXIMISÉS
              </div>
          </div>
          <div className="circle lower">
            <IncreaseIcons></IncreaseIcons>
            <div className='texteCercle'>
                OCCUPATION DU
            </div>
            <div className='texteCercle'>
                BIEN OPTIMISÉE
            </div>
            </div>
          <div className="circle">
            <SatisIcons></SatisIcons>
            <div className='texteCercle'>
              EXPÉRIENCE VOYAGEURS AMÉLIORÉE
            </div>
          </div>
          <div className="circle lower">
            <DoveIcons></DoveIcons>
              <div className='texteCercle'>
              LIBERTÉ ET SÉRÉNITÉ
              </div>
              <div className='texteCercle'>
                RETROUVÉES
              </div>
            </div>
        </div>
      </div>
    );
  }
  
  export default MiddlePart;