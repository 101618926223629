import React from 'react';
import IncreaseIconsSVG from './IncreaseIcons.svg';

function IncreaseIcons() {
    return (
        <div>
            <img src={IncreaseIconsSVG} alt="Increase Icons" />
        </div>
    );
}

export default IncreaseIcons;