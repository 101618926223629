import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import useMediaQuery from '@mui/material/useMediaQuery'; // Import de useMediaQuery
import logo from '../../ressources/logo/LOGO-V2-4K.png';

const pages = ['Accueil', 'Offre Conciergerie', 'Contact'];

function Header() {
    const location = useLocation();
    const [currentPage, setCurrentPage] = useState('Accueil');
    const [menuOpen, setMenuOpen] = useState(false);
    const isMobile = useMediaQuery('(max-width:960px)'); // Vérification de la taille de l'écran

    useEffect(() => {
        const pathname = location.pathname.substring(1); // Ignore le premier slash
        const page = pathname === '' ? 'Accueil' : pathname.charAt(0).toUpperCase() + pathname.slice(1);
        setCurrentPage(page);
    }, [location]);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const handleMenuItemClick = () => {
        setMenuOpen(false); // Ferme le menu lorsque l'onglet est sélectionné
        window.scrollTo(0, 0); // Remonte en haut de la page
    };

    return (
        <>
            <AppBar position="static" sx={{ backgroundColor: 'white', boxShadow: 'none' }}>
                <Toolbar disableGutters>
                    <Container sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                            <Link to="/" style={{ textDecoration: 'none' }}>
                                <Avatar alt="Logo" src={logo} style={{ width: '200px', height: 'auto', marginBottom: '-40px', display: { xs: 'none', md: 'block' } }} />
                            </Link>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                            <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'flex-end', paddingRight: '100px', marginBottom: '5px' }}>
                                {pages.map((page, index) => (
                                    <Link
                                        key={page}
                                        to={page === 'Accueil' ? '/' : `/${page.toLowerCase().replace(' ', '-')}`}
                                        style={{
                                            textDecoration: 'none',
                                            color: page === currentPage || (page === 'Offre Conciergerie' && location.pathname === '/offre-conciergerie') ? '#ffffff' : 'black',
                                        }}
                                    >
                                        <Button
                                            variant="text"
                                            color="inherit"
                                            size="small"
                                            sx={{
                                                height: '30px',
                                                fontFamily: 'PoliceTitre',
                                                backgroundColor: page === currentPage || (page === 'Offre Conciergerie' && location.pathname === '/offre-conciergerie') ? '#5C5654' : '#ffffff',
                                                borderRadius: 0,
                                                textTransform: 'none',
                                                fontSize: '1rem',
                                                outline: 'none',
                                                '&:hover': {
                                                    backgroundColor: page === currentPage || (page === 'Offre Conciergerie' && location.pathname === '/offre-conciergerie') ? '#5C5654' : '#E8D5CC',
                                                    borderRadius: 0,
                                                }
                                            }}
                                        >
                                            {page}
                                        </Button>
                                    </Link>
                                ))}
                            </Box>
                        </Box>
                    </Container>
                </Toolbar>
            </AppBar>

            <Drawer
                anchor="left"
                open={menuOpen}
                onClose={toggleMenu}
                sx={{
                    display: { xs: 'block', md: 'none' },
                    '& .MuiDrawer-paper': {
                        width: '80%',
                        backgroundColor: '#ffffff',
                        fontSize: '1.5rem',
                        height: '100vh',
                        overflow: 'hidden',
                    },
                }}
            >
                <Box sx={{ width: '100%', padding: '20px 0', textAlign: 'center', overflowY: 'auto' }}>
                    {pages.map((page, index) => (
                        <Link
                            key={page}
                            to={page === 'Accueil' ? '/' : `/${page.toLowerCase().replace(' ', '-')}`}
                            style={{
                                textDecoration: 'none',
                                color: page === currentPage || (page === 'Offre Conciergerie' && location.pathname === '/offre-conciergerie') ? '#ffffff' : 'black',
                            }}
                            onClick={handleMenuItemClick} // Appelle la fonction lorsque l'onglet est sélectionné
                        >
                            <Button
                                variant="text"
                                color="inherit"
                                size="large"
                                sx={{
                                    width: '100%',
                                    fontFamily: 'PoliceTitre',
                                    backgroundColor: page === currentPage || (page === 'Offre Conciergerie' && location.pathname === '/offre-conciergerie') ? '#5C5654' : '#ffffff',
                                    borderRadius: 0,
                                    textTransform: 'none',
                                    fontSize: '2rem',
                                    '&:hover': {
                                        backgroundColor: page === currentPage || (page === 'Offre Conciergerie' && location.pathname === '/offre-conciergerie') ? '#5C5654' : '#E8D5CC',
                                        borderRadius: 0,
                                    }
                                }}
                            >
                                {page}
                            </Button>
                        </Link>
                    ))}
                </Box>
            </Drawer>

            {/* Bouton pour ouvrir le menu sur mobile */}
            {isMobile && (
                <Box
                    sx={{
                        position: 'fixed',
                        bottom: '20px',
                        right: '20px',
                        zIndex: '999',
                    }}
                >
                    <Button
                        onClick={toggleMenu}
                        sx={{
                            backgroundColor: 'gray',
                            color: 'white',
                            borderRadius: '50%',
                            width: '50px',
                            height: '60px',
                            fontSize: '3rem',
                            '&:hover': {
                                backgroundColor: 'darkgray',
                            }
                        }}
                    >
                        <MenuIcon fontSize='medium' />
                    </Button>
                </Box>
            )}
        </>
    );
}

export default Header;
