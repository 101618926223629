import React from 'react';
import './MiddlePart.css'; // Importer le fichier CSS pour appliquer les styles
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CheckIcon from '@mui/icons-material/Check';
import TodayIcon from '@mui/icons-material/Today';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';

function MidllePart() {
  return (
    <div className="two-cards-container">
      <div className="welcome-container">
        <div className="line"></div>
        <div className="titre">Nos offres de Conciergerie</div>
        <div className="line"></div>
      </div>
      <div className="cards-wrapper">
        <Card className="card">
          <CardContent>
            <Typography variant="h5" component="h3" className="card-title">
              <div className='Titre'>
                Forfait mise en place
              </div>
            </Typography>
            <Typography variant="body1">
              <ul className="card-list">
                <li><CheckIcon /> Premier ménage approfondi</li>
                <li><CheckIcon /> 1er achat de consommables (thé, café, sucre, sel, poivre, huile, vinaigre, papier toilette, liquide vaisselle, savon main...)</li>
                <li><CheckIcon /> Création ou reprise de votre annonce sur les différentes plateformes</li>
                <li><CheckIcon /> Création d'un livret d'accueil</li>
                <li><CheckIcon /> Photos professionnelles de votre logement</li>
                <li><CheckIcon /> Conseils d'agencement</li>
                <li><CheckIcon /> Achat de la literie (draps, serviette de douche, tapis de bain, torchon)</li>
                <li><CheckIcon /> Installation d'une boite à clé</li>
              </ul>
            </Typography>
          </CardContent>
          <div className="card-text">
            <Typography variant="h4" component="h3" className="section-title">
                <div className='Titre3' >
                    Sur devis
                </div>
            </Typography>
          </div>
        </Card>
        <Card className="card">
          <CardContent>
            <Typography variant="h5" component="h3" className="card-title">
              <div className='Titre'>
                Services de conciergerie
              </div>
            </Typography>
            <Typography variant="body1">
              <ul className="card-list">
                <li><CheckIcon /> OTA Airbnb, Booking Abritel</li>
                <li><CheckIcon /> Optimisation des prix à la nuitée (Tarification dynamique / yield management)</li>
                <li><CheckIcon /> Gestion des imprévus</li>
                <li><CheckIcon /> Intendance de votre calendrier</li>
                <li><CheckIcon /> Petites réparations</li>
                <li><CheckIcon /> Suivi de l’entretien de votre bien et du bon fonctionnement de vos équipements</li>
                <li><CheckIcon /> Changement de la literie / ménage (frais de ménage à la charge du voyageur)</li>
                <li><CheckIcon /> Réapprovisionnement des consommables</li>
                <li><CheckIcon /> Service aux voyageurs</li>
                <li><CheckIcon /> Optimisation de l'annonce</li>
                <li><CheckIcon /> Check-in / check-out</li>
                <li><CheckIcon /> Communication et assistance aux voyageurs 7j/7</li>
              </ul>
            </Typography>
          </CardContent>
          <div className="card-text">
            <Typography variant="h5" component="h3" className="section-title">
                <div className='Titre3' >
                    23% TTC de commission sur les revenus générés
                </div>
            </Typography>
          </div>
        </Card>
      </div>
      <div className="welcome-container">
        <div className="line2"></div>
        <div className="titre4" >Besoin de nous ?</div>
        <div className="line2"></div>
      </div>
      <div className="button-container">
        <Link to="/contact" className="link-button">
        <Button variant="contained" className="rendez-vous-button">
            <TodayIcon style={{ marginRight: '5px' }} /> 
            Prendre Rendez-Vous
            </Button>
        </Link>
      </div>
    </div>
  );
}

export default MidllePart;
